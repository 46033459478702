import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { Route, Switch } from "wouter";
import { Loading } from "./components/Loading";
import { NotFound } from "./components/NotFound";
const IndexPage = React.lazy(() => import("./pages"));
const RoomPage = React.lazy(() => import("./pages/rooms/[id]"));
const Router = () => {
    return (_jsxs(Switch, { children: [_jsx(Route, { path: "/", component: IndexPage }, void 0),
            _jsx(Route, Object.assign({ path: "/rooms/:id" }, { children: (params) => _jsx(RoomPage, { roomId: params.id }, void 0) }), void 0),
            _jsx(Route, { component: NotFound }, void 0)] }, void 0));
};
export const App = () => {
    return (_jsx(Suspense, Object.assign({ fallback: _jsx(Loading, {}, void 0) }, { children: _jsx(Router, {}, void 0) }), void 0));
};
