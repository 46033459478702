import { jsx as _jsx } from "react/jsx-runtime";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "jotai";
import ReactDOM from "react-dom";
import { App } from "./app";
import { theme } from "./theme";
const mountPosition = document.createElement("div");
mountPosition.id = "app";
document.querySelector("body").appendChild(mountPosition);
ReactDOM.render(_jsx(Provider, { children: _jsx(ChakraProvider, Object.assign({ theme: theme }, { children: _jsx(App, {}, void 0) }), void 0) }, void 0), mountPosition);
